import gsap from "gsap";

// Desktop Nav Bar
let currentActive = undefined;

function animateEnterDropdown(container) {
  container.style = "";
  gsap.from(container, {
    opacity: 0,
    y: 5,
    duration: 0.2,
  });
}

function animateExitDropdown(container) {
  gsap.fromTo(
    container,
    {
      opacity: 1,
      y: 0,
    },
    {
      display: "none",
      opacity: 0,
      y: 5,
      duration: 0.2,
    },
  );
}

function onClickOutside() {
  document.addEventListener("click", initDropDownCloseEvent);
}

function initDropDownCloseEvent(event) {
  if (!currentActive.contains(event.target)) closeDropdown(currentActive);
}

function closeDropdown(element) {
  element.previousElementSibling.setAttribute("aria-expanded", false);

  animateExitDropdown(element);

  currentActive = undefined;

  document.removeEventListener("click", initDropDownCloseEvent);
}

Array.from(document.querySelectorAll("nav .btn-dropdown")).forEach(
  (element) => {
    element.addEventListener("click", (event) => {
      let isExpended = element.getAttribute("aria-expanded") === "true";
      const container = element.nextElementSibling;
      event.stopPropagation();

      if (currentActive && isExpended) {
        closeDropdown(currentActive);
        return;
      }

      if (currentActive) {
        closeDropdown(currentActive);
      }

      element.setAttribute("aria-expanded", true);

      container.style.display = "block";

      animateEnterDropdown(container);
      onClickOutside();

      currentActive = container;
    });
  },
);

// Mobile Nav Bar
const mobileMenu = document.querySelector(".mobile-menu");
const closeMenu = document.querySelector(".close-menu");
const hamburgerBtn = document.querySelector(".hamburger-btn");
const backdropShadow = document.querySelector(".backdrop-shadow");
const menuContainer = document.querySelector(".menu-container");
const btnExpand = document.querySelectorAll(".btn-expand");

hamburgerBtn.addEventListener("click", () => {
  mobileMenu.style.display = "";
  gsap.fromTo(
    menuContainer,
    {
      x: "100%",
    },
    {
      x: 0,
      ease: "expoScale(0.5,7,power1.in)",
      duration: 0.2,
    },
  );
  gsap.to(backdropShadow, {
    opacity: 1,
  });
});

[closeMenu, backdropShadow].forEach((element) => {
  element.addEventListener("click", () => {
    gsap.timeline().to(menuContainer, {
      x: "100%",
      ease: "expoScale(0.5,7,power1.out)",
      duration: 0.2,
    });
    gsap.to(
      backdropShadow,
      {
        opacity: 0,
        duration: 0.2,
        onComplete: () => (mobileMenu.style.display = "none"),
      },
      "<",
    );
  });
});

let currentActiveMenuItem = undefined;

Array.from(btnExpand).forEach((element) => {
  element.addEventListener("click", (event) => {
    const btn = event.currentTarget;
    const isExpended = btn.getAttribute("aria-expanded") === "true";

    btn.setAttribute("aria-expanded", !isExpended);

    event.currentTarget.nextElementSibling.style.display = isExpended
      ? "none"
      : "block";

    currentActiveMenuItem = event.currentTarget.nextElementSibling;
  });
});


// header inner dropdown
document.addEventListener('DOMContentLoaded', () => {
  // Select all toggle buttons and their respective dropdowns
  const dropdowns = [
    { toggle: 'salesforceToggle', dropdown: 'salesforceDropdown' },
    { toggle: 'servicenowToggle', dropdown: 'servicenowDropdown' }
  ];

  // Function to close all dropdowns
  const closeAllDropdowns = () => {
    dropdowns.forEach(({ dropdown }) => {
      const dropdownElement = document.getElementById(dropdown);
      dropdownElement.style.display = 'none';
    });
  };

  // Add click event listeners to each toggle
  dropdowns.forEach(({ toggle, dropdown }) => {
    const toggleElement = document.getElementById(toggle);
    const dropdownElement = document.getElementById(dropdown);

    toggleElement.addEventListener('click', (event) => {
      event.stopPropagation(); // Prevent event bubbling
      const isVisible = dropdownElement.style.display === 'block';

      // Close all dropdowns, then toggle the clicked one
      closeAllDropdowns();
      if (!isVisible) {
        dropdownElement.style.display = 'block';
      }
    });
  });

  // Close all dropdowns when clicking outside
  document.addEventListener('click', () => {
    closeAllDropdowns();
  });
});
