import Swiper from "swiper";
import Swal from "sweetalert2";
import { Navigation, Autoplay } from "swiper/modules";
import { countryJSON } from "./country";
import Form from "./form";

const upcomingBatches = new Form(document.querySelector("#upcoming-batch"));

Array.from(document.querySelectorAll('[open-modal="upcoming-batch"]')).forEach(
  (btn) => {
    btn.addEventListener("click", (event) => {
      upcomingBatches.modal.querySelector('[name="batch-id"]').value =
        event.target.dataset.courseId;
    });
  },
);

upcomingBatches.modal.querySelector('[name="country"]').insertAdjacentHTML(
  "beforeend",
  Object.keys(countryJSON)
    .map(
      (key) =>
        ` <option value="${countryJSON[key]}">${countryJSON[key]}</option>`,
    )
    .join(""),
);

upcomingBatches.validate.addField(
  upcomingBatches.modal.querySelector('[name="country"]'),
  [
    {
      rule: "required",
    },
  ],
)
  .addField(upcomingBatches.modal.querySelector('[name="coupon"]'), [
    {
      rule: "customFunction",
      validator: function () {
        const couponValue = upcomingBatches.modal.querySelector('[name="coupon"]').value;
        return couponValue === "" || couponValue === "WINTERDEAL2025"; // Allow empty or correct code
      },
      errorMessage: "Invalid coupon code",
    },
  ]);

upcomingBatches.modal
  .querySelector('[name="country"]')
  .addEventListener("change", (event) => {
    const stateContainer =
      upcomingBatches.modal.querySelector("#state-container");
    if (event.target.value === "India") {
      stateContainer.style.display = "block";
      upcomingBatches.validate.addField(
        stateContainer.querySelector("select"),
        [
          {
            rule: "required",
          },
        ],
      );
    } else {
      stateContainer.style.display = "none";
      try {
        upcomingBatches.validate.removeField(
          stateContainer.querySelector("select"),
        );
      } catch (error) {
        console.log(error);
      }
    }
  });

let currencyvalue = "INR";

const europeanCountries = [
  { country: "Russia", code: "RU" },
  { country: "Germany", code: "DE" },
  { country: "France", code: "FR" },
  { country: "Italy", code: "IT" },
  { country: "Spain", code: "ES" },
  { country: "Poland", code: "PL" },
  { country: "Ukraine", code: "UA" },
  { country: "Romania", code: "RO" },
  { country: "Netherlands", code: "NL" },
  { country: "Belgium", code: "BE" },
  { country: "Sweden", code: "SE" },
  { country: "Czech Republic (Czechia)", code: "CZ" },
  { country: "Greece", code: "GR" },
  { country: "Portugal", code: "PT" },
  { country: "Hungary", code: "HU" },
  { country: "Belarus", code: "BY" },
  { country: "Austria", code: "AT" },
  { country: "Switzerland", code: "CH" },
  { country: "Serbia", code: "RS" },
  { country: "Bulgaria", code: "BG" },
  { country: "Denmark", code: "DK" },
  { country: "Slovakia", code: "SK" },
  { country: "Finland", code: "FI" },
  { country: "Norway", code: "NO" },
  { country: "Ireland", code: "IE" },
  { country: "Croatia", code: "HR" },
  { country: "Moldova", code: "MD" },
  { country: "Bosnia and Herzegovina", code: "BA" },
  { country: "Albania", code: "AL" },
  { country: "Lithuania", code: "LT" },
  { country: "Slovenia", code: "SI" },
  { country: "North Macedonia", code: "MK" },
  { country: "Latvia", code: "LV" },
  { country: "Estonia", code: "EE" },
  { country: "Luxembourg", code: "LU" },
  { country: "Montenegro", code: "ME" },
  { country: "Malta", code: "MT" },
  { country: "Iceland", code: "IS" },
  { country: "Andorra", code: "AD" },
  { country: "Liechtenstein", code: "LI" },
  { country: "Monaco", code: "MC" },
  { country: "San Marino", code: "SM" },
  { country: "Holy See (Vatican City)", code: "VA" },
];

// Creating a map from the array
const europeanCountriesMap = new Map(
  europeanCountries.map(({ code, country }) => [code, country]),
);

const proxyUrl = "https://api.country.is/";

const requestOptions = {
  method: "GET",
  redirect: "follow",
};

try {
  fetch(proxyUrl, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      const countryget = europeanCountriesMap.get(data.country);
      if (data.country == "US") {
        currencyvalue = "USD";
      } else if (data.country == "GB") {
        currencyvalue = "GBP";
      } else if (data.country == "AU") {
        currencyvalue = "AUD";
      } else if (data.country == "CA") {
        currencyvalue = "CAD";
      } else if (countryget != null && countryget != "") {
        currencyvalue = "EUR";
      } else {
        currencyvalue = "INR";
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      currencyvalue = "INR";
    });
} catch (error) {
  console.log(error);
}

(async function () {
  const batches = await fetch("/wp-json/wp/v2/batches?_fields[]=acf");
})();

upcomingBatches.validate.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);
    formData.append("website-link", window.location.href);
    formData.append("order", true);
    formData.set('currencydata', currencyvalue);

    const username = `${formData.get("first-name")} ${formData.get("last-name")}`;
    const email = `${formData.get("email")}`;
    const phone = `${formData.get("phone")}`;

    let couponCode = `${formData.get("coupon")}`;

    if (couponCode == '') {
      formData.set('coupon', 'no coupon');
    }

    console.log(formData);

    upcomingBatches.modal.classList.add("loading");

    let response = await fetch("/linking", {
      body: formData,
      method: "POST",
    });

    if (!response.ok) throw Error("Something Went Wrong");

    response = await response.text();

    console.log(response);

    if (response == "Technical Error") {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong! Please try again after sometime...",
        icon: "error",
        confirmButtonText: "Close",
      });
      window.location.href = window.location.href + '?cachebuster=' + new Date().getTime();
      return false;
    }

    const [orderId, exchangeAmount, amount] = response.split("__");

    const image =
      "https://www.shreysharma.com/wp-content/uploads/2019/03/s2-labs-logo-e1556267108374.png";
    const description = `at ${currencyvalue}${exchangeAmount} /- only`;

    const options = {
      key: "rzp_live_70fFDBLuF857hF",
      protocol: "https",
      hostname: "api.razorpay.com",
      name: "S2 Labs",
      description,
      image,
      order_id: orderId,
      prefill: {
        name: username,
        currency: currencyvalue,
        email,
        contact: phone,
      },
      theme: {
        color: "#001342",
      },
      handler: async function (response) {
        try {
          Swal.fire({
            title: "Note:",
            html: '<p style="font-size: 20px;line-height: 32px;text-align: center;font-size: 20px;">Please wait while we process your registration. Do not refresh the page or navigate away while this is happening. Thank you for your patience.<p>',
            didOpen: () => {
              Swal.showLoading();
            },
            allowEscapeKey: false,
            allowOutsideClick: false,
          });

          formData.append("payment-id", response.razorpay_payment_id);
          formData.set("order", false);

          let res = await fetch("/linking", {
            body: formData,
            method: "POST",
          });

          if (!res.ok) throw Error("Something Went Wrong");

          console.log(await res.text());

          Swal.fire({
            title: "Registration Successful:",
            html: '<p style="font-size: 20px;line-height: 32px;text-align: center;font-size: 20px;">Hello, We’re glad to inform you that your payment has been successful! Thank you for choosing S2 Labs.<p>',
            allowEscapeKey: true,
            allowOutsideClick: true,
          });
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong! Please try again after sometime...",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
      },
    };

    let rzp = new Razorpay(options);

    rzp.on("payment.failed", function (response) {
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    });

    rzp.open();
  } catch (error) {
    console.log(error);
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "Close",
    });
  } finally {
    upcomingBatches.modal.classList.remove("loading");
    upcomingBatches.modal.querySelector("form").reset();
    upcomingBatches.toggleModal();
  }
});

var swiper = new Swiper(".swiper-upcoming-batches", {
  spaceBetween: 0,
  slidesPerView: 1,
  modules: [Navigation, Autoplay],
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  autoplay: {
    delay: 4500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    1024: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
  },
});


// let requestBtns = document.querySelectorAll('[open-modal="request-callback-modal"]');
// requestBtns.forEach((requestBtn)=>{
//   requestBtn.addEventListener('click', ()=>{
//     let rqForm = document.querySelector("#request-callback-modal");
//     rqForm.classList.toggle("opacity-0");
//     rqForm.classList.toggle("pointer-events-none");
//     document.body.classList.toggle("modal-active");
//   });
// });

// request callback js
const requestCallback = new Form(
  document.querySelector("#notify-modal"),
);

requestCallback.validate.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);
    formData.append("website-link", window.location.href);
    requestCallback.modal.classList.add("loading");
    let response = await fetch("/wp-content/themes/s2-labs/student-form.php", {
      body: formData,
      method: "POST",
    });
    if (!response.ok) throw Error("Something Went Wrong");
    let textData = await response.text();
    if (textData.indexOf('Technical Error') > 0) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong! Please try again after sometime...",
        icon: "error",
        confirmButtonText: "Close",
      });
      window.location.href = window.location.href + '?cachebuster=' + new Date().getTime();
      return false;
    }
    Swal.fire({
      title: "Thank you!",
      html: `We’ve received your preferences and we'll notify you via email when your preferred batch is scheduled.`,
      icon: "success",
      confirmButtonText: "Close",
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "Close",
    });
  } finally {
    requestCallback.modal.classList.remove("loading");
    requestCallback.modal.querySelector("form").reset();
    requestCallback.toggleModal();
  }
});

if (document.getElementById('searchForm')) {
  // code for sending value to search page bar
  const homeSearchForm = document.getElementById('searchForm');
  const homeSearchInput = document.getElementById('searchInput');

  homeSearchForm.addEventListener('submit', function (event) {
    event.preventDefault(); // Prevent default form submission
    const searchTerm = homeSearchInput.value.trim();

    if (searchTerm) {
      // Redirect to search page with search term as a URL parameter
      window.location.href = `/search?query=${encodeURIComponent(searchTerm)}`;
    }
  });
}


